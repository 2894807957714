var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.infoList),function(info){return [_c('CToast',{key:info.message,attrs:{"show":true,"header":info.header,"color":info.color}},[_vm._v(" "+_vm._s(info.message)+". ")])]})],2),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(" Claim ")]),_vm._v(" Information ")]),_c('CCardBody',[_c('CRow',[_c('CCol',[_c('CInput',{attrs:{"label":"Claim No","horizontal":""},model:{value:(_vm.obj.claimNo),callback:function ($$v) {_vm.$set(_vm.obj, "claimNo", $$v)},expression:"obj.claimNo"}})],1),_c('CCol',[_c('CInput',{attrs:{"horizontal":"","label":"Date","type":"date","value":_vm.computeDate},on:{"change":_vm.setDate}})],1),_c('CCol',[_c('CSelect',{attrs:{"horizontal":"","value":_vm.selectedClaimState,"options":_vm.claimStates,"label":"Claim State"},on:{"update:value":function($event){_vm.selectedClaimState=$event}}})],1)],1),_c('CRow',[_c('CCol',[_c('CDataTable',{attrs:{"items":_vm.computedClaimItems,"fields":_vm.claimFields},scopedSlots:_vm._u([{key:"show_index",fn:function(ref){
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"show_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"type":"date"},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)]}},{key:"show_item",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"min":"1"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)]}},{key:"show_description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CTextarea',{attrs:{"placeholder":"","rows":"1"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)]}},{key:"show_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"min":"1"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)]}},{key:"show_unit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"min":"1"},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}})],1)]}},{key:"show_price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"min":"0","step":"0.01"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1)]}},{key:"show_total",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getTotalItemPrice(item))+" ")])]}},{key:"show_move",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","size":"sm","disabled":index === 0},on:{"click":function($event){return _vm.moveItem(index, 'up')}}},[_vm._v(" ↑ ")]),_c('CButton',{attrs:{"color":"primary","size":"sm","disabled":index === _vm.computedClaimItems.length - 1},on:{"click":function($event){return _vm.moveItem(index, 'down')}}},[_vm._v(" ↓ ")])],1)]}},{key:"show_remove",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onRemoveClaimItem(item)}}},[_vm._v(" Remove ")])],1)]}},{key:"footer",fn:function(){return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addNewItem()}}},[_vm._v("Add")])],1),_c('td',{staticClass:"text-right",attrs:{"colspan":"6"}},[_c('strong',[_vm._v("Grand Total:")])]),_c('td',[_vm._v(_vm._s(_vm.grandTotal.toFixed(2)))])]},proxy:true}])})],1)],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","size":"sm","color":"primary"},on:{"click":_vm.submit}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Submit")],1)],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }